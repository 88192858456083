@import 'styles/variables';

.footer {
  display: flex;
  justify-content: center;
  max-width: 1680px;
  margin: auto;
  background-color: #1b2126;
  font-size: 14px;
  line-height: 24px;
  padding: $padding-xxl 0px;
  width: 100%;

  .footerContainer {
    display: flex;
    user-select: none;
    margin: 0px $margin-xl;
    flex: 1 1 0px;

    .footerColumn {
      display: flex;
      justify-content: center;

      .footerContent {
        display: flex;
        flex-direction: column;
        max-width: fit-content;

        .emailSupport {
          outline: none;
          color: $color-white !important;
          display: flex;
        }
      }
    }

    .subContainer {
      display: flex;
      flex: 1 1 0px;
      padding: 10px 0px;
      margin: 20px 0px;
      border-top: 1px solid $border-tertiary-grey;
      border-bottom: 1px solid $border-tertiary-grey;

      .subContent {
        display: flex;
        justify-content: center;
        flex: 1 1 0px;
        align-items: center;
        color: $color-white;
        font-size: inherit;
      }

      .policies {
        display: flex;
        align-items: center;

        .policy {
          color: $color-white;
        }

        .circle {
          height: 4px;
          width: 4px;
          border-radius: 50%;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          background: $color-white;
          margin: 4px 8px;
        }
      }

      .socialMedia {
        font-size: $font-size-xl;
        display: flex;
        justify-content: flex-end;

        .social {
          padding: 0px $padding-md;
        }
      }
    }

    h4 {
      color: #727579;
    }

    a {
      color: $color-white;
      line-height: 2.4;
    }
  }
}
