@import 'styles/variables.scss';

.home_header {
  display: flex;
  width: 100%;
  background-color: white;
  user-select: none;
  padding: 8px 60px;
  align-items: center;
  z-index: 10;
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.175) !important;

  .logo_menu_icon_container {
    display: flex;
    justify-content: space-between;
  }

  .logo_container {
    margin-right: $margin-xxl;

    .logo {
      user-select: none;
      width: 60px;
      height: 60px;
      cursor: pointer;
    }
  }

  .menu_icon_container {
    display: none;

    .menu_icon {
      font-size: 30px;
      color: #1890ff;
    }
  }

  .menu_auth_container {
    display: flex;
    align-items: center;
    flex: 1;

    .spacer {
      flex: 1;
    }

    .menu_container {
      display: flex;

      .home_header_menu_link {
        color: #06ceb3;
        font-size: $font-size-lg;
        font-weight: bold;
        margin-right: $margin-xl;

        &:hover {
          color: #1890ff;
        }
      }
    }

    .auth_btn_container {
      display: flex;

      .auth_btn {
        display: flex;
        justify-content: center;
        align-items: center;
        user-select: none;
        cursor: pointer;
        background-color: #06ceb3;
        padding: 11px 13px;
        color: white;
        border-radius: 4px;

        &2 {
          border-radius: 4px;
          display: flex;
          justify-content: center;
          align-items: center;
          user-select: none;
          cursor: pointer;
          background-color: #1890ff;
          padding: 11px 13px;
          color: white;
          margin-left: $margin-lg;

          &:hover {
            background-color: #1066b5;
          }
        }

        &_text {
          margin-left: 3px;
          font-weight: 700;
          font-size: $font-size-md;
        }

        &_icon {
          color: white;
        }

        &:hover {
          background-color: #037464;
        }
      }
    }
  }

  &_link {
    margin-right: 40px;
    color: #06ceb3;
    font-size: 15px;
    font-weight: bold;

    &:hover {
      color: #1890ff;
    }
  }
}

@media (max-width: 1063px) {
  .home_header {
    .logo_container {
      margin-right: 20px;
    }
  }
}

@media (max-width: 1024px) {
  .hide_menu {
    display: none !important;
  }

  .home_header {
    flex-direction: column;

    .logo_menu_icon_container {
      width: 100%;
      align-items: center;
    }

    .menu_auth_container {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: flex-start;
      padding: 15px;

      .menu_container {
        flex-direction: column;

        a {
          margin-right: 0 !important;
          padding-bottom: 15px !important;
        }
      }

      .auth_btn_container {
        flex-direction: column;
        width: 100%;

        .auth_btn {
          margin-bottom: 15px;
        }

        .auth_btn2 {
          margin-left: 0 !important;
        }
      }
    }

    .menu_icon_container {
      display: block;
    }
  }
}

@media (max-width: 768px) {
  .home_header {
    padding: 15px 15px;
  }
}
