@import 'styles/variables';

.section_packages_container {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: $padding-xxl 60px;
  user-select: none;
  align-items: center;
  background-color: #f4f6fd;

  .section_packages_details {
    margin-bottom: $margin-lg;
    display: flex;
    flex-direction: column;
    align-items: center;

    .section_packages_title {
      margin-bottom: $margin-lg;
      color: $color-black;
      font-size: $font-size-xxl;
      line-height: 44px;
      font-weight: 600;
    }

    .section_packages_info {
      color: #6e6e6e;
      line-height: 1.8;
      font-size: $font-size-lg;
      text-align: center;
    }
  }

  .price_cards_container {
    .card_container {
      display: flex;
      justify-content: center;
      padding: $padding-lg;
      border: none;

      .price_cards {
        transform: translateY(0);
        background: $color-white;
        border-radius: 4px;
        box-shadow: 7px 5px 30px 0 rgba(72, 73, 121, 0.15);
        cursor: pointer;
        max-width: 400px;
        flex: 1 1 0px;
        padding: 30px 35px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .price_card_pricing {
          padding: $padding-xl $padding-xl $padding-md;
          display: flex;
          flex-direction: column;
          align-items: center;
          color: $color-black;

          h3 {
            font-size: $font-size-xl;
            line-height: 30px;
            font-weight: 600;
            text-align: center;
            padding: 3px;
            border-radius: 4px;
          }

          h1 {
            font-size: $font-size-xxxl;
            font-weight: 600;
            text-align: center;
          }

          p {
            color: $color-primary-grey;
            white-space: nowrap;
            font-size: $font-size-lg;
            margin-top: -17px;
          }
        }

        .divider {
          background: $bg-secondary-grey;
          width: 100%;
          height: 1px;
        }

        .price_card_details {
          padding: $padding-xl $padding-xl $padding-md;
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: $font-size-lg;
          white-space: nowrap;
          line-height: 32px;

          .price_card_details_strong {
            display: flex;
          }

          p {
            color: $color-primary-grey;
          }
        }

        .btn_container {
          .buy_btn {
            margin-top: 35px;
            align-self: flex-start;
            height: 50px;
            background: -webkit-linear-gradient(to right, #06ceb3, #1890ff);
            background: linear-gradient(to right, #06ceb3, #1890ff);
            border: none;
            border-radius: 4px;
            color: #ffffff;
            cursor: pointer;
            font-weight: 500;
            outline: none;
            font-size: 17px;
            padding: 8px 32px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            user-select: none;
            white-space: nowrap;
          }
        }
      }

      .price_cards:hover {
        transition: 0.5s;
        transform: translateY(-15px);
      }
    }
  }
}
