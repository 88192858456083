.section_what_container {
  background-color: white;
  display: flex;
  flex: 1;
  align-items: center;
  padding: 0 60px;

  .section_what_content_container {
    display: flex;
    flex-direction: column;
    padding-right: 20px;
    flex: 1;

    .what_title {
      user-select: none;
      margin-bottom: 16px;
      color: #000000;
      font-size: 34px;
      line-height: 44px;
      font-weight: 600;
      margin-bottom: 35px;
    }

    .what_text {
      color: #666666;
      line-height: 1.8;
      font-size: 17px;
      vertical-align: baseline;
      user-select: none;
      padding: 0;
      margin: 2px;
    }

    .what_btn {
      margin-top: 35px;
      align-self: flex-start;
      height: 50px;
      background: -webkit-linear-gradient(to right, #06ceb3, #1890ff);
      background: linear-gradient(to right, #06ceb3, #1890ff);
      border: none;
      border-radius: 4px;
      color: #ffffff;
      cursor: pointer;
      font-weight: 500;
      outline: none;
      font-size: 17px;
      padding: 8px 32px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      user-select: none;
      white-space: nowrap;
    }
  }

  .section_what_image_container {
    display: flex;
    flex: 1;
  }
}

@media (max-width: 768px) {
  .section_what_container {
    flex-direction: column;
    padding: 0 15px;

    .section_what_content_container {
      padding-top: 60px;
    }
  }
}
