@import 'styles/variables';

.page_container {
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex: 1;
  background-image: url('./images/back8.jpg');
  background-size: cover;
}
