@import 'styles/variables';

.section_why_container {
  background-color: white;
  display: flex;
  flex: 1;
  align-items: center;
  padding: 64px 60px;
  position: relative;

  .why_background {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }

  .section_why_content_container {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    flex: 1;

    .why_title {
      user-select: none;
      margin-bottom: 16px;
      color: #000000;
      font-size: 34px;
      line-height: 44px;
      font-weight: 600;
      margin-bottom: 35px;
    }

    .why_motto_text {
      color: orange;
      font-size: 17px;
      font-weight: 700;
    }

    .why_text {
      color: #666666;
      line-height: 1.8;
      font-size: 17px;
      vertical-align: baseline;
      user-select: none;
      padding: 0;
      margin: 2px;
      display: flex;

      .why_text_icon {
        color: #1890ff;
        margin-right: $margin-md;
        font-size: 20px;
        font-weight: 600;
      }
    }

    .why_btn {
      margin-top: 35px;
      align-self: flex-start;
      height: 50px;
      background: -webkit-linear-gradient(to right, #06ceb3, #1890ff);
      background: linear-gradient(to right, #06ceb3, #1890ff);
      border: none;
      border-radius: 4px;
      color: #ffffff;
      cursor: pointer;
      font-weight: 500;
      outline: none;
      font-size: 17px;
      padding: 8px 32px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      user-select: none;
      white-space: nowrap;
    }
  }

  .section_why_image_container {
    display: flex;
    flex: 1;
  }
}

@media (max-width: 768px) {
  .section_why_container {
    flex-direction: column;
    padding: 64px 15px;
  }
}
